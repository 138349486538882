<template>
  <div class="login-page align-vertical">
    <div class="form-wrapper align-vertical-middle">
      <el-form :model="form" ref="form" :rules="rules">
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :xs="18" :sm="12" :md="10">
            <h2 v-if="isPosNamed"><mark>{{posname}}</mark></h2>
            <h2 v-else>This POS has no name</h2>
            <p class="mb-40">Log in to your account</p>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :xs="18" :sm="12" :md="10">
            <el-form-item prop="username">
              <el-input v-model="form.username" placeholder="Username">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :xs="18" :sm="12" :md="10">
            <el-form-item prop="password">
              <el-input v-model="form.password" placeholder="Password" show-password
                        type="password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :xs="18" :sm="12" :md="10">
            <el-form-item prop="submit">
              <el-button type="primary" @click="submit('form')" style="width: 100%">Login</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      posname: '',
      isPosNamed: false,
      rules: {
        username: [
          { required: true, message: 'Please input username', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' },
          { min: 6, message: 'Length should be greater 6', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('login', this.form)
            .then(user => {
              if (!this.isPosNamed) {
                localStorage.setItem('posname', 'POS-' + user.name)
              }
              this.$onCommandParams('home')
              // if (user.hasOwnProperty('role') && user.role === 'admin') {
              //   this.$router.push('/posname')
              // } else {
              //   this.$onCommandParams('home')
              // }
            })
            .catch(err => {
              this.$message({
                showClose: true,
                message: 'Error ' + err.message,
                type: 'error'
              })
            })
        }
      })
    }
  },

  created () {
    if (localStorage.getItem('posname')) {
      this.posname = localStorage.getItem('posname')
      this.isPosNamed = true
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/scss/variables";

  .login-page {
    //background: $background-color;
    margin-left: -30px;
    margin-right: -30px;
    height: 100%;
    width: 100%;

    .form-wrapper {
      width: 100%;
    }

  }

  @media (max-width: 768px) {
    .layout-container .container .view.login-page {
      margin-left: -5px;
      margin-right: -5px;
      max-width: calc(100% + 10px);
    }
  }
</style>
